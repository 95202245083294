@charset "UTF-8";

#kontakt {
    background-color: var(--blue);
    padding: 280px 0 280px 0;
    @media only screen and (max-width: 868px) {
        padding: 140px 0 140px 0;
    }

    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
        color: var(--black);
        margin-bottom: 40px;
    }

    .contact-btn {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        gap: 30px;
        a {
            background-color: var(--black);
            padding: 8px 35px;
            border-radius: var(--button-radius);
            height: 52px;
            width: 210px;
            display: inline-block;
            line-height: 36px;
            text-decoration: none;
            color: var(--white);
            font-weight: 700;
            text-align: center;
            transition: background-color 200ms, color 200ms, box-shadow 200ms;

            &:before, &:after, span:before, span:after {
                transition: background-color 200ms;
            }

            &:hover {
                background-color: var(--white) !important;
                color: var(--black) !important;
            }

            &:before, &:after, span:before, span:after {
                background-color: var(--black) !important;
            }
        }
    }
}