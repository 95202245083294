#services {
    padding-top: 280px;
    padding-bottom: 480px;
}

.title {
    padding-left: 20px;
    padding-right: 20px;
}

.introText {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 0;
}



.card-stacker {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: grid;
    grid-template-rows: repeat(var(--cards-count), var(--card-height));
    gap: 40px 0;
    position: relative;
}

.card {
    position: sticky;
    top: 0;
    box-sizing: content-box;
    width: 600px;
    max-width: calc(100vw - 40px);
}

.hiddenCard {
    opacity: 0;
}

.service {
    width: 100%;
    height: 350px;
    background-color: var(--blue);
    padding: 80px;
    border-radius: var(--button-radius);
    will-change: transform;
    overflow: hidden;
    transform-origin: center top;
    transition: all 500ms linear;
    opacity: 0.97;
}

.service-title {
    width: 90%;
    font-size: 2rem;
    font-weight: bolder;
    margin-bottom: 1em;
}

.bodytext2 {
    width: 90%;
    font-size: 1.2rem;
    line-height: 1.8rem;
}

@media only screen and (max-width: 868px) {
    #services {
        padding-top: 140px;
        padding-bottom: 330px;
    }
}

@media only screen and (max-width: 700px) {
    .service {
        padding: 40px;
        height: 380px;
    }
    .card-stacker {
        grid-template-rows: repeat(var(--cards-count), 380px);
    }
}

@media only screen and (max-width: 400px) {
    .service-title {
        width: 80%;
        font-size: 1.5rem;
        font-weight: bolder;
        margin-bottom: 1em;
    }
    .service {
        padding: 40px;
        height: 400px;
    }
    .card-stacker {
        grid-template-rows: repeat(var(--cards-count), 400px);
    }
}
@media only screen and (max-width: 350px) {
    .service-title {
        font-size: 1.3rem;
    }
    .service .bodytext2 {
        font-size: 1.1rem;
        hyphens: auto;
    }
}