@charset "UTF-8";

#nav {
    position: fixed;
    bottom: 40px;
    pointer-events: none;
    z-index: 3;

    #nav-control {
        display: flex;
        position: relative;
        z-index: 1;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        background-color: var(--black);
        border-radius: 60px;
        padding: 5px;
        border: 1px solid grey;
        transition: border-color 200ms;
        pointer-events: auto;
        cursor: pointer;
    }

    #nav-btn {
        position: relative;
        background-color: var(--blue);
        border-radius: 50%;
        height: 50px;
        width: 50px;

        transition: all 300ms;


        span {
            position: absolute;
            display: block;
            border-radius: 50%;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform-origin: center center;
            &:before, &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 22px;
                height: 3px;
                border-radius: 3px;
                transition: all 200ms ease-in-out;
                transform-origin: 0 0;
                background-color: white !important;
            }

            &:before {
                transform: translateX(-50%) translateY(-6px);
            }

            &:after {
                transform: translateX(-50%) translateY(3px);
            }
        }
    }

    #nav-btn:hover {
        transition: all 300ms;
        background:
                linear-gradient(
                        170deg,
                        rgba(0, 0, 0, 0),
                        rgba(0, 0, 0, 0));
        span {
            &:before, &:after {
                background-color: black !important;
            }
        }
    }

    --letterDuration: 300ms;
    --letterDelay: 100ms;
    --letterTotal: 400ms;

    .nav-wrapper {
        max-width: 155px;
        width: 155px;
        transition: width var(--letterTotal), max-width var(--letterTotal);
    }

    #nav-logo {
        padding-left: 15px;
        font-size: 40px;
        line-height: 40px;
        font-weight: 700;
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;
        max-width: 58px;
        width: 58px;
        transition: width var(--letterTotal), max-width var(--letterTotal), margin-right var(--letterTotal);
        user-select: none;

        span {
            opacity: 1;
            transform: scale3d(0, 1,1);
            &:first-child, &:last-child {
                opacity: 1;
                transform: scale3d(1,1,1);
                order: -1
            }

            &:nth-child(2) {transition: opacity var(--letterDuration) linear, transform var(--letterDuration) linear;}
            &:nth-child(3) {transition: opacity var(--letterDuration) linear calc(1 * var(--letterDelay)), transform var(--letterDuration) linear calc(1 * var(--letterDelay));}
            &:nth-child(4) {transition: opacity var(--letterDuration) linear calc(2 * var(--letterDelay)), transform var(--letterDuration) linear calc(2 * var(--letterDelay));}
            &:nth-child(5) {transition: opacity var(--letterDuration) linear calc(3 * var(--letterDelay)), transform var(--letterDuration) linear calc(3 * var(--letterDelay));}

        }
    }

    #nav-content {
        /*background-color: var(--black);*/

        display: flex;
        pointer-events: none;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        padding: 30px 20px 90px 20px;
        width: 370px;
        max-width: 100%;
        margin-bottom: -52px;
        border: 1px solid grey;
        opacity: 0;
        transition: opacity 50ms linear;
        background: rgba(0, 0, 0, 0.4);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);

        .contact {
            text-align: left;
            padding-right: 20px;
            border-right: 1px solid #aaaaaa;
            color: var(--white);

            p {
                margin-bottom: 4px;
            }

            a, a:visited {color: var(--white); }

            a:hover {
                text-decoration: none;
            }
        }

        .anchors {
            padding-left: 20px;

            li {
                padding: 5px;
            }

            web-anchor {
                font-weight: 700;
                transition: color 200ms;

                &:hover, &.active {
                    color: var(--blue);
                }
            }
        }
    }

    &.open {
        pointer-events: auto;
        .nav-wrapper {
            @media screen and (max-width: 390px) {
                max-width: calc(100vw - 20px);
                width: calc(100vw - 20px);
            }

            @media screen and (min-width: 391px) {
                max-width: 333px;
                width: 333px;
            }
        }

        #nav-logo {
            width: 206px;
            max-width: 206px;
            span {
                opacity: 1;
                transform: scale3d(1,1,1);
                order: -1
            }

        }

        #nav-control {
            border-color: grey;
        }

        #nav-btn {
            transform: rotateZ(-360deg);

            span {
                &:before {
                    transform: rotateZ(45deg) translateX(-50%) translateY(-50%);
                }

                &:after {
                    transform: rotateZ(-45deg) translateX(-50%) translateY(-50%);
                }
            }
        }

        #nav-content {
            opacity: 1;
            pointer-events: auto;
            transition: opacity 200ms linear var(--letterTotal);
        }
    }
}