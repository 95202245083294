:root {
    --blue: #00cde8;
    --black: black;
    --white: white;
    --dark-grey: #070707;
    --grey: #535353;

    --wrapper-width: 1440px;
    --content-width: 777px;

    --small: 640;
    --medium: 768;
    --large: 1024;

    --title: 65px;
    --subtitle: 27px;
    --text: 16px;

    --box-radius: 20px;
    --button-radius: 28px;

    --button-primary-color: var(--blue);
    --button-sec-color: var(--black);

}


.wrapper {
    width: 100%;
    max-width: var(--wrapper-width);
}

.content {
    width: 100%;
    max-width: var(--content-width);
}