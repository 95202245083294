.overlay {
    --overlayAnimDuration: 300ms;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity var(--overlayAnimDuration), transform var(--overlayAnimDuration) ease-in-out;

    pointer-events: none;
    opacity: 0;
    transform: scale(0.7);
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

    &.visible {
        pointer-events: auto;
        transform: scale(1);
        opacity: 1;
    }

    .overlay-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        transition: transform 300ms ease-in-out;

        .overlay-close {
            position: absolute;
            right: 40px;
            top: 40px;
            height: 40px;
            border: none;
            z-index: 10;

            &:before, &:after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                width: 22px;
                height: 3px;
                border-radius: 3px;

            }

            &:before {
                transform: translateX(-50%) translateY(-50%) rotateZ(-45deg) ;
            }

            &:after {
                transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
            }
        }
    }

    .overlay-scroll {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 100%;
        max-height: 100%;
        margin: 0 auto;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior-block: contain;
    }

    .overlay-content {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        width: var(--content-width);
        max-width:  100%;

        padding: 10vh 20px 60px 20px;
        margin-left: auto;
        margin-right: auto;

        & > * {
            max-width: 100%;
        }

    }


    button {
        border-radius: 50%;
        background: var(--blue);
        width: 40px;
        height: 40px;
    }
}

.overlay-content {
    h2 {
      font-size: 30px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 1.875em;
    }

    h3 {
      font-size: 18px;
      line-height: 32px;
      font-weight: 700;
    }

    p, li, a {
      font-size: 18px;
      line-height: 32px;
      font-weight: 400;
    }

    ul {
      margin-left: 1.2em;
      margin-bottom: 1.875em;

      li {
        display: flex;
        align-items: start;

        &:before {
          content: "•";
          margin-right: 1.2em;
        }
      }
    }
}