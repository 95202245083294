@charset "UTF-8";

/*// Default theme*/
@import "@splidejs/splide/dist/css/splide.min.css";

#project {
    .project-detail {
        display: none;
    }

    background: var(--black);
    padding: 300px 0;
    padding-top: 280px;


    h1 {
        margin-bottom: 100px;
        padding-left: 20px;
        padding-bottom: 20px;
    }

    .logo-slider {
        margin-bottom: 150px;
        img {
            height: 65px;
        }
        @media screen and (max-width: 868px) {
            margin-bottom: 70px;
            img {
                max-height: 60px;
            }
        }

        div {
            width: 962px;
            max-width: calc(100vw - 40px);
            margin-inline: auto;
        }

        li {
            user-select: none;
        }
    }

    .logo-slider.splide {
        @media screen and (min-width: 320px) {

            .splide__track {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 15%;
                    background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                    pointer-events: none;
                    z-index: 1;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 15%;
                    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                    pointer-events: none;
                    z-index: 1;
                }
            }
        }
    }

    article {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .teaser {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-start;

        border: 1px solid var(--grey);
        border-radius: 44px;
        background-color: var(--dark-grey);
        padding: 63px 58px;

        @media screen and (max-width: 868px) {
            flex-flow: column-reverse nowrap;
            padding: 20px;
        }

        .text {
            margin-right: 50px;
            @media screen and (max-width: 868px) and (min-width: 450px) {
                margin-top: 60px;
            }
            @media screen and (max-width: 868px) {
                margin-right: 0;
            }

            img.logo {
                display: block;
                margin-bottom: 22px;
            }

            > p {
                font-size: var(--text);

                strong {
                    font-weight: 700;
                }

                &:last-of-type {
                    margin-bottom: 40px;
                }
            }
        }

        .sizer {
            width: calc(40vh + 70px);
            height: 40vh;

            @media screen and (max-width: 1024px) {
                width: calc(35vh + 80px);
                height: 35vh;
            }

            @media screen and (max-width: 868px) {
                width: 65%;
                height: auto;
                aspect-ratio: 100/100;
            }
        }

        .project-preview {
            @media screen and (max-width: 868px) {
                width: 100%;
            }
        }

        .device-preview {
            position: absolute;
            height: 100%;
            @media screen and (min-width: 868px) and (max-width: 1000px) {
                max-height: 70vw;
                top: 30px;
            }
            max-height: 70vw;
            aspect-ratio: 466/604;
            top: 0;
            right: 0;
            @media screen and (max-width: 868px) {
                position: absolute;
                height: auto;
                width: 65%;
                left: 50%;
                right: initial;
                margin: auto;
                transform: translateX(-50%);
            }
        }

        .content-image {
            position: absolute;
            top: 5.48%;
            left: 6.99%;
            width: 86%;
            height: 89%;
            object-fit: cover;
            z-index: 1;
            border-radius: 1%;
        }

        .device-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            pointer-events: none;
        }
    }

    .splide {
        max-width: var(--wrapper-width);
        width: 100%;

        @media screen and (min-width: 1200px) {

            .splide__track {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 15%;
                    background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                    pointer-events: none;
                    z-index: 1;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 15%;
                    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                    pointer-events: none;
                    z-index: 1;
                }
            }
        }

        article {
            width: 100%;
            max-width: 962px;
        }


    }

    .splide__arrows {
        position: absolute;
        top: calc(50% - 70px - 52px);
        left: 50%;
        transform: translateX(-50%);
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        max-width: 1130px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .splide__arrow {
            position: initial;
            transform: none;
            background-color: var(--button-primary-color);
            width: 52px;
            height: 52px;
            opacity: 1;

            &:disabled {
                opacity: .26;
            }

            svg {
                fill: none;
            }
        }
    }

    .show-all-projects {
        margin-top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .project-slider {
        position: relative;
        transition: opacity 300ms linear;

        &.show-all {
            @media screen and (max-width: 868px) {
                padding-left: 20px;
                padding-right: 20px;
            }

            article {
                margin: auto;
                margin-bottom: 30px;
            }

            .splide__arrows {
                display: none;
            }

            .splide__list, .splide__track {
                display: contents;
            }
        }
    }
}

.blue-button {
    background-color: var(--button-primary-color);
    padding: 8px 35px;
    border-radius: var(--button-radius);
    height: 52px;
    display: inline-block;
    line-height: 36px;
    text-decoration: none;
    color: var(--black) !important;
    transition: all 200ms ease-in-out;
}

.blue-button:hover {
    background-color: white;
}

@media screen and (max-width: 868px) {
    #project {
        padding: 150px 0;
        padding-top: 140px;

        article {
            padding-top: 12vh;
            padding-bottom: 0;
        }

        .splide__arrows {
            margin-top: 35px;
        }
    }
}