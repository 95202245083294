@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(../font/monserat.woff2) format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url(../font/monserat.woff2) format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url(../font/monserat.woff2) format('woff2');
}