.waterMorphContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 100vw;
    top: 0;
    overflow: hidden;
    left: 0;
}
.waterMorphObject {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.canvasWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    pointer-events: initial;
}

.canvasWrapper canvas {
    animation: visible 2s;
}

@keyframes visible {
    from {
        opacity: 0.1;
    }
    to {
        opacity: 0.5;
    }
}

.transition-transform {
    transition-timing-function: ease-out;
    transition-duration: 1500ms;
    transition-property: transform;
}

.blur {
    position: fixed;
    width: 62%;
    height: 62%;
    z-index: -100;
}

.blur-effect {
    backdrop-filter: blur(7px);
    -webkit-mask: radial-gradient(black 0%, black 40%, transparent 60%);
}

.blur-effect-safari {
    -webkit-backdrop-filter: blur(7px);
    -webkit-mask: radial-gradient(black 0%, black 40%, transparent 60%);
}

.dragable-area {
    position: fixed;
    width: 50%;
    height: 50%;
    z-index: 100;
    cursor: grab;
}

@keyframes squigglevision {
    0% { filter: url("#turbulence-1"); }
    1% { filter: url("#turbulence-2"); }
    2% { filter: url("#turbulence-3"); }
    3% { filter: url("#turbulence-4"); }
    4% { filter: url("#turbulence-5"); }
    5% { filter: url("#turbulence-6"); }
    6% { filter: url("#turbulence-7"); }
    7% { filter: url("#turbulence-8"); }
    8% { filter: url("#turbulence-9"); }
    9% { filter: url("#turbulence-10"); }
    10% { filter: url("#turbulence-11"); }
    11% { filter: url("#turbulence-12"); }
    12% { filter: url("#turbulence-13"); }
    13% { filter: url("#turbulence-14"); }
    14% { filter: url("#turbulence-15"); }
    15% { filter: url("#turbulence-16"); }
    16% { filter: url("#turbulence-17"); }
    17% { filter: url("#turbulence-18"); }
    18% { filter: url("#turbulence-19"); }
    19% { filter: url("#turbulence-20"); }
    20% { filter: url("#turbulence-21"); }
    21% { filter: url("#turbulence-22"); }
    22% { filter: url("#turbulence-23"); }
    23% { filter: url("#turbulence-24"); }
    24% { filter: url("#turbulence-25"); }
    25% { filter: url("#turbulence-26"); }
    26% { filter: url("#turbulence-27"); }
    27% { filter: url("#turbulence-28"); }
    28% { filter: url("#turbulence-29"); }
    29% { filter: url("#turbulence-30"); }
    30% { filter: url("#turbulence-31"); }
    31% { filter: url("#turbulence-32"); }
    32% { filter: url("#turbulence-33"); }
    33% { filter: url("#turbulence-34"); }
    34% { filter: url("#turbulence-35"); }
    35% { filter: url("#turbulence-36"); }
    36% { filter: url("#turbulence-37"); }
    37% { filter: url("#turbulence-38"); }
    38% { filter: url("#turbulence-39"); }
    39% { filter: url("#turbulence-40"); }
    40% { filter: url("#turbulence-41"); }
    41% { filter: url("#turbulence-42"); }
    42% { filter: url("#turbulence-43"); }
    43% { filter: url("#turbulence-44"); }
    44% { filter: url("#turbulence-45"); }
    45% { filter: url("#turbulence-46"); }
    46% { filter: url("#turbulence-47"); }
    47% { filter: url("#turbulence-48"); }
    48% { filter: url("#turbulence-49"); }
    49% { filter: url("#turbulence-50"); }
    50% { filter: url("#turbulence-51"); }
    51% { filter: url("#turbulence-52"); }
    52% { filter: url("#turbulence-53"); }
    53% { filter: url("#turbulence-54"); }
    54% { filter: url("#turbulence-55"); }
    55% { filter: url("#turbulence-56"); }
    56% { filter: url("#turbulence-57"); }
    57% { filter: url("#turbulence-58"); }
    58% { filter: url("#turbulence-59"); }
    59% { filter: url("#turbulence-60"); }
    60% { filter: url("#turbulence-61"); }
    61% { filter: url("#turbulence-62"); }
    62% { filter: url("#turbulence-63"); }
    63% { filter: url("#turbulence-64"); }
    64% { filter: url("#turbulence-65"); }
    65% { filter: url("#turbulence-66"); }
    66% { filter: url("#turbulence-67"); }
    67% { filter: url("#turbulence-68"); }
    68% { filter: url("#turbulence-69"); }
    69% { filter: url("#turbulence-70"); }
    70% { filter: url("#turbulence-71"); }
    71% { filter: url("#turbulence-72"); }
    72% { filter: url("#turbulence-73"); }
    73% { filter: url("#turbulence-74"); }
    74% { filter: url("#turbulence-75"); }
    75% { filter: url("#turbulence-76"); }
    76% { filter: url("#turbulence-77"); }
    77% { filter: url("#turbulence-78"); }
    78% { filter: url("#turbulence-79"); }
    79% { filter: url("#turbulence-80"); }
    80% { filter: url("#turbulence-81"); }
    81% { filter: url("#turbulence-82"); }
    82% { filter: url("#turbulence-83"); }
    83% { filter: url("#turbulence-84"); }
    84% { filter: url("#turbulence-85"); }
    85% { filter: url("#turbulence-86"); }
    86% { filter: url("#turbulence-87"); }
    87% { filter: url("#turbulence-88"); }
    88% { filter: url("#turbulence-89"); }
    89% { filter: url("#turbulence-90"); }
    90% { filter: url("#turbulence-91"); }
    91% { filter: url("#turbulence-92"); }
    92% { filter: url("#turbulence-93"); }
    93% { filter: url("#turbulence-94"); }
    94% { filter: url("#turbulence-95"); }
    95% { filter: url("#turbulence-96"); }
    96% { filter: url("#turbulence-97"); }
    97% { filter: url("#turbulence-98"); }
    98% { filter: url("#turbulence-99"); }
    99% { filter: url("#turbulence-100"); }
    100% { filter: url("#turbulence-101"); }
}

canvas {
    opacity: 0.5;
}

.squiggle {
    animation: squigglevision 5s infinite alternate;
}
