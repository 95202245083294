@charset "UTF-8";

footer {
    background-color: var(--black);
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 140px;
    padding-top: 140px;

    .logo {
        margin-bottom: 4vh;
    }

    .address {
        text-align: center;
        margin-bottom: 4vh;
        .subtitle{
            .footer-address {
                text-decoration: none;
                .footer-street {
                    text-decoration: underline;
                }
            }
            .footer-address:hover {
                .footer-street {
                    text-decoration: none;
                }
            }
            margin-bottom: 30px;
        }
    }

    .links {
        ul {
            display: flex;
            li {
                padding: 0 10px;
                img {
                    width: 80px;
                }
            }
        }

        web-anchor {
            text-decoration: underline;

            &:hover {
            text-decoration: none;
            }
        }
    }

    #datenschutz, #job-application , #impressum {
        display: none;
    }
}