@charset "utf-8";
#dividers-arranger {
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 99vw;
    min-height: 100vh;
    overflow: hidden;

    /*#dividers-arranger-inner {*/
    /*    height: 100%;*/
    /*    width: 100%;*/
    /*    border-left: 40px solid black;*/
    /*    border-right: 40px solid black;*/
    /*}*/

    .divider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100vh;
        height: 100vh;
        background-color: var(--black);
        will-change: transform;
        transition: transform 25ms linear;
        overflow: hidden;

        &.bottom {
            top: unset;
            bottom: 0;
        }
    }

    img {
        height: 100%;
        min-height: 100%;
        object-fit: cover;

        transition: opacity 200ms;
        filter: saturate(65%);
    }

    img:not([src]) {
        opacity: 0;
    }
}

main section {
    background-color: black;
}


.image-divider{
    height: 100vh;
    width: 100%;
    background-color: transparent;
}