.image-overlay-component {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: fit-content;
  height: fit-content;
  display: flex;
  filter: drop-shadow(10px 10px 0.5rem rgba(0,0,0,0.5));
}

.image-overlay-component img {
  max-width: min(800px, 80vw);
  max-height: 80vh;
  object-fit: contain;
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 50%;
  background-color: var(--black);
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.close-button:hover {
  filter: drop-shadow(0px 0px 0.5rem white);
}

.close-button:before, .close-button:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 2px;
  border-radius: 3px;
  background-color: white;
}

/*.close-button:hover:before, .close-button:hover:after {
  background-color: var(--blue) !important;
}*/


.close-button:before {
  transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
}

.close-button:after {
  transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
}

@media only screen and (max-width: 700px) {
  .image-overlay-component {
    position: absolute;
  }

  .image-overlay-component img {
    max-width: 80vw;
    max-height: 80vh;
    object-fit: contain;
  }

  .close-button {
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
  }

}