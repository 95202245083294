@charset "UTF-8";

#word-spinner {
    user-select: none;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    line-height: 1.1;
    animated-word {
        user-select: none;
        .anim-word {
            white-space: nowrap;
        }
    }
}

animated-word {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    -webkit-user-select: none;
    /*overflow: hidden;*/
    text-align: center;
    .placeholder {
        opacity: 0;
    }

    .anim-word {
        display: block;
        position: absolute;
        width: 100%;
        top: 0;
        opacity: 0;

        span {
            position: relative;
            display:inline-block;
            opacity: 0;
        }
    }
}

h1.title animated-word {
    display: inline-block;
    width: auto;
    text-align: left;
}

#eenie {
    .placeholder {
        opacity: 1;
    }
}

#miney {
    .placeholder {
        opacity: 1;
    }
}

@media only screen and (max-width: 800px) {

    #word-spinner {
        line-height: 1.25;
    }
}