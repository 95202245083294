.feed-text {
    font-size: 1.1rem;
    text-decoration: underline;
}

.feed-text:hover {
    text-decoration: none;
}
#feed {
    position: absolute;
    z-index: 1;
    top: 20px;
    background-color: var(--black);
    border-radius: 15px;
    width: 320px;
    @media screen and (max-width: 360px) {
        width: calc(100vw - 20px);
        right: 10px;
    }

    @media screen and (min-width: 391px) {
        right: 20px;
        width: 320px;
    }
    padding: 28px;

    opacity: 0;
    pointer-events: none;

    transition: opacity 300ms;

    &.visible {
        opacity: 1;
        pointer-events: auto;
    }

    .head {
        margin-bottom: 30px;
    }
    .feed-title {
        font-weight: 700;
    }

    .close-btn {
        position: absolute;
        top: 20px;
        right: 20px;

        border-radius: 50%;
        background-color: var(--blue);
        width: 27px;
        height: 27px;

        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 15px;
            height: 2px;
            border-radius: 3px;
        }

        &:before {
            transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
        }

        &:after {
            transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
        }
    }

    .feed-container {
        display:flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: stretch;
    }

    .feed {
        margin-left: -15px;
        margin-right: -15px;
        padding: 15px;
        cursor: pointer;
        text-decoration: none;
        height: 100px;
        border: 1px solid black;
        transition: all 300ms ease-in-out;
    }

    .feed:hover {
        background-color: #262626;
        border-radius: 10px;
        border: 1px solid #444444;
    }

/*    .feed:hover .apply {
        background-color: var(--blue);
        scale: 1.05;
        color: black;
    }

    .apply {
        transition: all 200ms linear;
    }*/

    .feed:not(:last-child) {
        min-height: 70px;
        margin-bottom: 0px;
    }

    .left-img {
        display: flex;
        flex-flow: row nowrap;
        align-items: start;
        gap: 20px;

        .image {
            border-radius: 10px;
            height: 70px;
            background-color: var(--blue);
            width: 40%
        }

        .text {
            width: 60%;
            font-size: 14px;
        }
    }

    .row1, .row2 {
        margin-bottom: 0;
    }

    .row1 {
        color: var(--blue);
    }

    .bg-img {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        background-color: var(--blue);
        padding: 10px;
        height: 70px;

        border-radius: 10px;

        button, web-anchor {
            border-radius: 10px;
            background-color: var(--black);
            padding: 10px;
            text-align: center;
            font-size: 14px;
            font-weight: bolder;
            width: 50%;
        }
    }
}