@charset "UTF-8";

@import "animatedhero/animatedhero.scss";

header {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: var(--blue);
  min-height: 100vh;
  width: 100%;

  .huge {
    font-size: 9vw;
    letter-spacing: 0.5vw;
    font-weight: 900;
  }

  .meta {
    position: absolute;
    z-index: 1;
    top: 26px;
    right: 26px;

    span {
      display: inline-block;
      position: relative;
      margin-left: 10px;
      text-align: center;
      vertical-align: center;
      width: 25px;

      &:before {
        content: '';
        position: absolute;
        background-color: var(--black);
        width: 30px;
        height: 30px;
        left: -3px;
        top: -5px;
        border-radius: 50%;
        z-index: -1;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  header {
    .huge {
      font-size: 12vw;
    }
  }
}

