body {
    font-family: Montserrat, sans-serif;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    max-width: 100vw;

    background-color: var(--black);
    color: var(--white);

    font-size: var(--text);
    line-height: 1.25;
}

* {
    box-sizing: border-box;
}

.black {
    color: var(--black);
}

.white {
    color: var(--white);
}

main {
    width: 100%;
}

.title {
    font-weight: bold;
    font-size: var(--title);
    text-align: center;
    margin-bottom: 30px;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }
}

.subtitle {
    font-size: var(--subtitle);
    font-weight: bold;
}

web-anchor {
    cursor: pointer;
}

.text {
    font-size: var(--text);
}

p a:hover{
    text-decoration: none;
}

.wrapper-width {
    max-width: var(--wrapper-width);
    margin-left: auto;
    margin-right: auto;
}

.content-width {
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
}

.dark-gradient {
    background: linear-gradient(
            170deg,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.35)
    );
}


p {
    margin-bottom: 1.5em;
}

button, web-anchor.blue-button {
    transition: background-color 200ms, color 200ms, box-shadow 200ms;

    &:before, &:after, span:before, span:after {
        transition: background-color 200ms;
    }

    &:hover {
        background-color: var(--white) !important;
        color: var(--black) !important;
    }

    &:before, &:after, span:before, span:after {
        background-color: var(--black) !important;
    }

}