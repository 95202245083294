

section#team {
    --teammemberWidth: 350px;
    --gap: 40px;

    padding: 300px 0;
    padding-top: 280px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .teamgrid {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 100%;
        width: calc(var(--teammemberWidth) * 3 + var(--gap) * 2 + 40px);
        gap: var(--gap);

        &.hide {
            opacity: 0;
        }
    }

    .teammember {
        text-align: center;
        position: relative;
        height: 420px;
        width: 350px;
        perspective: 10px;

        .card-content {
            position: relative;
            cursor: pointer;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 1.2s;
            transform-style: preserve-3d;
            perspective: 1000px;

            .front {
                padding-top: 20px;
                background-color: var(--blue);
                border-radius: var(--box-radius);
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                & div > * {
                    display: block;
                    padding: 3px 0;
                }
                & > div:first-child {
                    padding-top: 10px;
                }
                & > web-anchor:first-child {
                    padding-top: 10px;
                    &:hover img{
                        animation: bounce 700ms cubic-bezier(.63,.09,.75,.46) infinite alternate;
                    }
                }

                img {
                    position: relative;
                    bottom: 0;
                    width: 420px;
                }
            }
        }

        label {
            span.flipper {
                position: absolute;
                bottom: 10px;
                right: 10px;
                background-color: black;
                border-radius: 50%;
                width: 30px;
                aspect-ratio: 1;
                user-select: none;
            }

            .back {
                span.flipper {
                    rotate: 45deg;
                }
            }
        }

        .more {
            display: none;
        }

        .more:checked ~ .card-content {
            transform: rotateY(180deg);
        }


        .name {
            font-weight: 700;
        }

    }

    h2 {
        margin-bottom: 100px;
        padding-left: 20px;
        padding-right: 20px;
    }


    .flipper {
        position: relative;

        &:before, &:after {
            content: "";
            position: absolute;
            background-color: white;
            top: 50%;
            left: 50%;
            width: 14px;
            height: 2px;
            border-radius: 3px;
            transform-origin: 0 0;
        }

        &:before {
            transform: rotateZ(0deg) translateX(-50%) translateY(-50%);
        }

        &:after {
            transform: rotateZ(90deg) translateX(-50%) translateY(-50%);
        }

    }
}


@media only screen and (max-width: 1200px) {
    section#team {


        .teamgrid {
        }

        .teammember {
            width: calc(50% - 40px);
            max-width: 350px;
        }
    }
}

@media only screen and (max-width: 868px) {
    section#team {
        padding: 150px 0;
        padding-top: 140px;
    }
}


@media only screen and (max-width: 700px) {
    section#team {


        .teamgrid {
        }

        .teammember {
            width: calc(100% - 40px);
            max-width: 350px;
        }
    }
}

@keyframes bounce {
    0% {
        transform: translate3d(0, 0, 0) rotateY(0deg) scaleY(1);
        animation-timing-function: ease-out, ease-out, linear;
    }
    45% {
        transform: translate3d(0, -3em, 0) rotateY(0deg) scaleY(1);
        animation-timing-function: ease-out, ease-out, linear;
    }
    50% {
        transform: translate3d(0, -3em, 0) rotateY(0deg) scaleY(1);
        animation-timing-function: ease-in;
    }
    100% {
        transform: translate3d(0, 0, 0) rotateY(0) scaleY(1);
        animation-timing-function: ease-out, ease-out, linear;
    }
}